<template name="evidencevideo">

  <video
    v-if="isLoaded"
    :ref="reference"
    :src="link"
    width="100%"
    :height="height"
    class="gallery-image bg-gray-800"
    controls="true"
  ></video>

  <div v-else class="gallery-image bg-gray-800 text-center pt-12 text-white">
    <div v-if="isLoaded">
      <i class="fas fa-spinner text-3xl"></i>
      <p class="opacity-75 pt-2">Media is loading</p>
    </div>
    <div v-if="isInError">
      <i class="fas fa-exclamation-triangle  text-3xl"></i>
      <p class="opacity-75 pt-2">Media could not be loaded</p>
    </div>
  </div>
</template>

<script>
import { GetFile } from "@/assets/js/file-image.service"; // image service

   const mimes = {
        'mp4': 'data:video/mov;base64,'
    };

export default {
  name: "evidencevideo",
  props: {
    url: String,
    reference: String,
    height: { type: String, required: true },
  },
  computed: {},
  data: function () {
    return {
      link: "loading",
      isLoaded: false,
      isInError: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
         var vm = this
   GetFile.video(this.url, mimes.mp4)
   .then(function(data) {
     console.log("c", data)
          vm.isLoaded = true
           vm.link = data;
           });

    },
  },
};
</script>

<style scoped>
.gallery-image {
  width: 100%;
  height: 100%;
  min-height: 160px !important;
  max-height: 160px !important;
  object-fit: cover;
  image-orientation: from-image !important;
}
</style>
